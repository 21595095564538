export const blue100 = '#C0E0F7';
export const blue200 = '#9CCDF1';
export const blue300 = '#79BBEB';
export const blue400 = '#57A8E4';
export const blue500 = '#3595DC';
export const blue600 = '#2A7BB7';
export const blue700 = '#206192';
export const blue800 = '#16476C';
export const blue900 = '#0D2D45';

export const green100 = '#bae7cf';
export const green200 = '#8cd8af';
export const green300 = '#5fc890';
export const green400 = '#31b970';
export const green500 = '#04aa51';
export const green600 = '#049b4a';
export const green700 = '#048c43';
export const green800 = '#036d34';
export const green900 = '#024e25';

export const purple100 = '#EDE9FB';
export const purple200 = '#CABEF4';
export const purple300 = '#A692EC';
export const purple400 = '#8267E4';
export const purple500 = '#5F3CDD';

export const red100 = '#fcdddd';
export const red200 = '#f79a9a';
export const red300 = '#f24646';
export const red400 = '#c73a3a';
export const red500 = '#9b2d2d';

export const yellow100 = '#fdf0bc';
export const yellow200 = '#fbe27a';
export const yellow300 = '#f9ca0c';
export const yellow400 = '#e3b80b';
export const yellow500 = '#cca60a';

export const orange100 = '#ffdbbe';
export const orange200 = '#ffb77d';
export const orange300 = '#ff7c11';
export const orange400 = '#e87110';
export const orange500 = '#d1660e';

export const white = '#ffffff';
export const neutral30 = '#fbfbfb';
export const neutral40 = '#f7f7f7';
export const neutral50 = '#f1f1f1';
export const neutral60 = '#ededed';
export const neutral70 = '#E3E3E3';
export const neutral80 = '#d4d4d4';
export const neutral90 = '#bfbfbf';
export const neutral100 = '#aaaaaa';
export const neutral200 = '#959595';
export const neutral300 = '#7f7f7f';
export const neutral400 = '#6a6a6a';
export const neutral500 = '#555555';
export const neutral600 = '#404040';
export const neutral700 = '#343434';
export const neutral800 = '#2b2b2b';
export const neutral900 = '#161616';
