import * as React from 'react';
import ExceptionLogger from '~/utils/exceptionLogger';
import NotFound from '~/components/NotFound';
import ServerError from '~/components/shared-components/ServerError';

interface Props {
  error: Error;
  statusCode?: number;
}

const ServerErrorPage = ({ statusCode, error }: Props) => {
  const uiPrefix = 'ServerErrorPage shown: ';
  const messagePrefix = statusCode ? `${statusCode} Error - ` : '';
  const messageBody = (error && error.message) || '';
  const message = `${uiPrefix}${messagePrefix}${messageBody}`;
  ExceptionLogger.error(message, error);
  return <ServerError />;
};

const Error = (props: Props) => {
  return props.statusCode === 404 ? (
    <NotFound />
  ) : (
    <ServerErrorPage {...props} />
  );
};

Error.getInitialProps = ({ res, err }: any) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null;
  return { statusCode, error: err };
};

// Overwrite the default layout which has a sidebar
Error.getLayout = function getLayout(page: React.ReactElement) {
  return <div>{page}</div>;
};

export default Error;
