import styled from '~/styled';
import Router from 'next/router';
import * as React from 'react';

import * as colors from '~/design-system/colors';
import { ArrowLeftIcon } from '@circleci/compass';
import * as screenSizes from '~/design-system/screenSizes';
import * as spacings from '~/design-system/spacings';
import * as Typography from '~/design-system/Typography';
import { trackPage } from '@circleci/analytics';
import getIsEnterprise from '~/utils/getIsEnterprise';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${spacings.s200};
  min-height: 100vh;
  background-color: #0d0d0d;
  background-image: url(/pipelines/background-images/stars.jpg);

  @media (min-width: ${screenSizes.medium}) {
    padding: ${spacings.s500};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: ${screenSizes.large};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  a {
    text-decoration: none;
    color: white;
  }
  @media (min-width: ${screenSizes.medium}) {
    background-image: url(/pipelines/background-images/graphic-error-500.png);
    background-repeat: no-repeat;
    background-position: right;
    flex-direction: row;
  }
`;

const ErrorCode = styled.p`
  font-weight: ${Typography.weights.bold};
  color: ${colors.neutral400};
  text-transform: uppercase;
  font-size: ${Typography.sizes.fs500};
  margin-bottom: ${spacings.s600};
`;

const ErrorMessage = styled.h1`
  font-size: 50px;
  color: ${colors.white};
  font-weight: ${Typography.weights.thin};
  margin: 0;

  @media (min-width: ${screenSizes.medium}) {
    font-size: 64px;
  }
`;

const Message = styled.p`
  color: ${colors.neutral60};
  font-size: ${Typography.sizes.fs500};
  line-height: 1.5;
  margin: 14px 0 30px;
  @media (min-width: ${screenSizes.medium}) {
    max-width: 50%;
  }
`;

const TextColumn = styled.div`
  text-align: center;

  @media (min-width: ${screenSizes.medium}) {
    text-align: left;
  }
`;

const Actions = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;

  > *:not(:last-child) {
    margin-right: ${spacings.s400};
  }

  @media (min-width: ${screenSizes.medium}) {
    justify-content: start;
  }
`;

const Button = styled.div`
  color: white;
  display: inline-flex;
  background: ${colors.neutral800};
  padding: 14px 18px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  align-items: center;
  justify-content: center;

  :hover {
    background: ${colors.neutral700};
  }
`;

const BackButton = styled(Button)``;

const HomeButton = styled(Button)`
  text-decoration: none;
`.withComponent('a');

const Image = styled.img`
  width: 100%;

  @media (min-width: ${screenSizes.medium}) {
    display: none;
  }
`;

type Props = {
  onGoBack?: () => void;
};

const ServerError: React.FC<Props> = ({ onGoBack = () => Router.back() }) => {
  React.useEffect(() => {
    trackPage('5XX Error', {});
  }, []);
  const isEnterprise = getIsEnterprise();
  const homeButtonLink = isEnterprise ? '/' : 'https://circleci.com';
  const homeButtonText = isEnterprise ? 'Go to Dashboard' : 'Go to Homepage';
  return (
    <Page>
      <Wrapper>
        <TextColumn>
          <ErrorCode>500 Error</ErrorCode>
          <ErrorMessage>Server Error</ErrorMessage>
          <Message>
            We’re preparing to serve you better. Please try refreshing, if that
            doesn’t work, try clearing the browser cache and cookies. If this
            continues, please connect with us at support@circleci.com.
          </Message>
          <Actions>
            <BackButton onClick={onGoBack}>
              <ArrowLeftIcon size={spacings.s400} /> <span>Go Back</span>
            </BackButton>
            <a href={homeButtonLink}>
              <HomeButton>{homeButtonText}</HomeButton>
            </a>
          </Actions>
        </TextColumn>
        <Image src="/pipelines/background-images/graphic-error-500.png" />
      </Wrapper>
    </Page>
  );
};

export default ServerError;
