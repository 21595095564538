export const s100 = '4px';
export const s200 = '8px';
export const s300 = '12px';
export const s400 = '16px';
export const s500 = '20px';
export const s600 = '24px';
export const s700 = '28px';
export const s800 = '32px';
export const s900 = '36px';
export const s1000 = '40px';
export const s1100 = '44px';
export const s1200 = '48px';
export const s1300 = '64px';
