import styled from '~/styled';
import * as colors from '~/design-system/colors';
import * as spacings from '~/design-system/spacings';

export const families = {
  mono: '"IBM Plex Mono", "Roboto Mono", Monaco, "Courier New", monospace',
  sansSerif: 'Inter, "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif',
};

export const sizes = {
  fs100: '11px',
  fs200: '12px',
  fs300: '13px',
  fs400: '14px',
  fs500: '16px',
  fs600: '18px',
  fs700: '20px',
  fs800: '24px',
  fs900: '28px',
};

// eslint-disable sort-keys
export const weights = {
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  neutral900: 900,
};
// eslint-enable sort-keys

export const letterSpacing = {
  ls100: '-1px',
  ls200: '-0.5px',
  ls300: 0,
  ls400: '0.5px',
  ls500: '1px',
};

export const Small = styled.p`
  color: ${colors.neutral500};
  font-size: ${sizes.fs200};
  letter-spacing: 0.1px;
  line-height: ${sizes.fs600};
`;

export const CapsLabel = styled.p`
  font-size: ${sizes.fs200};
  line-height: ${spacings.s400};
  letter-spacing: ${letterSpacing.ls400};
  font-weight: ${weights.bold};
  margin-top: 0;
  margin-bottom: ${spacings.s100};
  color: ${colors.neutral500};
`;
